import { months } from './helper';
import { createElement } from './utils';

const getWeekNumber = (d) => {
  // Copy date so don't modify original
  const date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
};

const setMonth = (target, today, year, month, callback) => {
  target.innerHTML = '';

  const header = createElement(
    'div',
    'header',
    `<span class="month">${months[month]}</span> <span class="year">${year}</span>`
  );
  const weeks = createElement('div', 'weeks');
  const firstDay = new Date(year, month, 1, 6);
  const prevMonth = firstDay.getMonth() > 0 ? firstDay.getMonth() - 1 : 11;
  const prevYear =
    prevMonth === 11 ? firstDay.getFullYear() - 1 : firstDay.getFullYear();
  const viewStartDate = firstDay;
  const prevMonthBtn = createElement('a', 'prev-month', '&laquo;');
  prevMonthBtn.setAttribute('href', '#');
  const nextMonthBtn = createElement('a', 'next-month', '&raquo;');
  nextMonthBtn.setAttribute('href', '#');

  if (viewStartDate.getDay() !== 1) {
    viewStartDate.setDate(
      firstDay.getDate() -
        firstDay.getDay() +
        (firstDay.getDay() === 0 ? -6 : 1)
    );
  }

  target.append(header);
  target.append(weeks);
  header.append(prevMonthBtn);
  header.append(nextMonthBtn);

  prevMonthBtn.addEventListener('click', (e) => {
    e.preventDefault();

    setMonth(target, today, prevYear, prevMonth, callback);
  });

  nextMonthBtn.addEventListener('click', (e) => {
    e.preventDefault();

    const newMonth = parseInt(month, 10) >= 11 ? 0 : parseInt(month, 10) + 1;
    const newYear = parseInt(month, 10) >= 11 ? parseInt(year + 1, 10) : year;

    setMonth(target, today, newYear, newMonth, callback);
  });

  let midSummer = false;

  if (month === 5) {
    midSummer = [];
    for (let i = 20; i < 27; i += 1) {
      if (
        new Date(
          `${viewStartDate.getFullYear()}-06-${i}T00:00:00.000Z`
        ).getDay() === 6
      ) {
        midSummer.push(i);
      }
    }

    midSummer.push(midSummer[0] + 1);
    midSummer.push(midSummer[0] - 1);
    midSummer.push(midSummer[0] - 2);
  }

  for (let w = 0; w <= 5; w += 1) {
    if (
      (month === 0 ? 11 : month - 1) === viewStartDate.getMonth() ||
      month === viewStartDate.getMonth()
    ) {
      const week = createElement('div', 'week');
      const weekNumberEl = createElement(
        'div',
        'day week-number',
        getWeekNumber(viewStartDate)
      );
      week.append(weekNumberEl);

      for (let d = 0; d <= 6; d += 1) {
        const dayElement = createElement('div', 'day');
        dayElement.setAttribute(
          'data-date',
          viewStartDate.toISOString().substr(0, 10)
        );
        dayElement.innerHTML = viewStartDate.getDate();

        if (viewStartDate.getTime() < today.getTime()) {
          dayElement.classList.add('disabled');
        }
        if (
          viewStartDate.getDate() === new Date().getDate() &&
          viewStartDate.getMonth() === new Date().getMonth()
        ) {
          dayElement.classList.add('today');
        }
        if (
          (viewStartDate.getDate() === 1 && viewStartDate.getMonth() === 0) ||
          (viewStartDate.getDate() === 31 && viewStartDate.getMonth() === 11)
        ) {
          dayElement.classList.add('prevented');
        }
        if (midSummer && midSummer.indexOf(viewStartDate.getDate()) >= 0) {
          dayElement.classList.add('prevented');
        }

        week.append(dayElement);

        viewStartDate.setDate(viewStartDate.getDate() + 1);
      }

      weeks.append(week);
    }
  }

  if (typeof callback === 'function') callback();
};

const setCalendar = (target, year, month, callback) => {
  if (typeof target !== 'undefined') {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);

    setMonth(
      target,
      today,
      typeof year === 'undefined' ? today.getFullYear() : year,
      typeof month === 'undefined' ? today.getMonth() : month,
      callback
    );
  }
};

const init = (year, month, callback) => {
  setCalendar(
    document.querySelector('.calendar-holder'),
    year,
    month,
    callback
  );
};

export { setCalendar, init };
