import './scss/style.scss';
import * as api from './js/vendor/api';
import times from './js/times';
import Navigation from './js/components/navigation';
import Login from './js/components/login';
import Calendar from './js/components/calendar';
import { setPayload } from './js/helper';
import { createElement } from './js/utils';

async function render(component, callback) {
  const app = document.querySelector('#app');
  app.innerHTML = component;
  callback();
}

async function getToken() {
  return window.sessionStorage.getItem('mokki-token');
}

function noScroll() {
  if (document.querySelector('body').classList.contains('noscroll')) {
    document
      .querySelectorAll('body,html')
      .forEach((el) => el.classList.remove('noscroll'));
  } else {
    document
      .querySelectorAll('body,html')
      .forEach((el) => el.classList.add('noscroll'));
  }
}

async function navigation() {
  const user = window.sessionStorage.getItem('mokki-user');

  if (user !== 'Janne') {
    document.querySelector('nav .admin').remove();
  }
  document.querySelectorAll('nav a:not(.toggle)').forEach((el) =>
    el.addEventListener('click', (e) => {
      e.preventDefault();

      document
        .querySelectorAll('.page')
        .forEach((page) => page.classList.add('hidden'));

      if (el.getAttribute('href') === '/password') {
        document.querySelector('.password').classList.toggle('hidden');
        noScroll();
      } else if (el.getAttribute('href') === '/change-user-password') {
        document.querySelector('.user-password').classList.toggle('hidden');
        noScroll();
      } else if (el.getAttribute('href') === '/logout') {
        window.sessionStorage.removeItem('mokki-token');
        window.sessionStorage.removeItem('mokki-user');
        window.location.reload();
      } else if (el.getAttribute('href') === '/') {
        noScroll();
      }

      document.querySelector('nav').classList.toggle('open');
    })
  );
}

async function changePassword() {
  document.querySelector('.change-password').addEventListener('submit', (e) => {
    e.preventDefault();

    const self = e.target;
    const newPassword = self.querySelector('[name="new_password"]');
    const passwordCheck = self.querySelector('[name="password_check"]');

    self.querySelectorAll('.alert').forEach((alert) => alert.remove());

    if (newPassword.value !== passwordCheck.value) {
      newPassword.classList.add('error');
      passwordCheck.classList.add('error');
      self.insertBefore(
        createElement(
          'div',
          'alert alert-danger',
          '<strong>Virhe!</strong><br />Salasanan tarkistus ei täsmää.'
        ),
        self.querySelector('.fields')
      );
    } else {
      newPassword.classList.remove('error');
      passwordCheck.classList.remove('error');

      const payload = setPayload(
        new URLSearchParams(new FormData(self)).toString()
      );

      api.put('/change-password', payload).then((response) => {
        if (response.status === 200) {
          self.insertBefore(
            createElement(
              'div',
              'alert alert-success',
              '<strong>Päivitetty!</strong><br />Salasanan päivitys onnistui.'
            ),
            self.querySelector('.fields')
          );
        } else {
          self.insertBefore(
            createElement(
              'div',
              'alert alert-danger',
              '<strong>Virhe!</strong><br />'
            ),
            self.querySelector('.fields')
          );
        }
      });
    }
  });
}

async function changeUserPassword() {
  document
    .querySelector('.change-user-password')
    .addEventListener('submit', (e) => {
      const self = e.target;

      e.preventDefault();

      self.querySelectorAll('.alert').forEach((alert) => alert.remove());

      const payload = setPayload(
        new URLSearchParams(new FormData(self)).toString()
      );

      api.put('/change-password', payload).then((response) => {
        if (response.status === 200) {
          self.insertBefore(
            createElement(
              'div',
              'alert alert-success',
              '<strong>Päivitetty!</strong><br />Salasanan päivitys onnistui.'
            ),
            self.querySelector('.fields')
          );
        } else {
          self.insertBefore(
            createElement(
              'div',
              'alert alert-danger',
              '<strong>Virhe!</strong><br />'
            ),
            self.querySelector('.fields')
          );
        }
      });
    });
}

async function loginWithToken(token) {
  api
    .post('/login', { token })
    .then((response) => {
      if (response.status === 200) {
        window.sessionStorage.setItem('mokki-token', response.data.token);
        window.sessionStorage.setItem('mokki-user', response.data.user.name);
      }
    })
    .catch((e) => {
      window.sessionStorage.removeItem('mokki-token');
      window.sessionStorage.removeItem('mokki-user');
      window.location.reload();
    });
  render(Navigation + Calendar, () => {
    document.querySelector('body').classList.remove('loggedout');
    document.querySelector('nav .toggle').addEventListener('click', (e) => {
      e.preventDefault();

      document.querySelector('nav').classList.toggle('open');
    });

    navigation();
    changePassword();
    changeUserPassword();

    times.init();
  });
}

async function loginWithPassword() {
  document.querySelector('.login-form').addEventListener('submit', (e) => {
    e.preventDefault();

    const el = e.target;

    el.classList.toggle('hidden');
    el.parentElement.querySelector('.loader').classList.toggle('hidden');
    el.parentElement
      .querySelectorAll('.alert')
      .forEach((alert) => alert.remove());

    const payload = setPayload(
      new URLSearchParams(new FormData(el)).toString()
    );

    api
      .post(el.getAttribute('action'), payload)
      .then((response) => {
        if (response.status === 200) {
          window.sessionStorage.setItem('mokki-token', response.data.token);
          window.sessionStorage.setItem('mokki-user', response.data.user.name);
          window.sessionStorage.setItem(
            'mokki-reservations',
            JSON.stringify(response.data.reservations)
          );
          window.sessionStorage.setItem(
            'mokki-disclaimer',
            response.data.disclaimer
          );
          window.location.reload();
          el.querySelector('[name="username"]').classList.toggle('error');
          el.querySelector('[name="password"]').classList.toggle('error');
        }
      })
      .catch(() => {
        el.classList.toggle('hidden');
        el.parentElement.querySelector('.loader').classList.toggle('hidden');
        el.querySelector('[name="username"]').classList.toggle('error');
        el.querySelector('[name="password"]').classList.toggle('error');

        const errorEl = createElement(
          'div',
          'alert-danger alert',
          '<strong>Virhe!</strong><br />Tarkista tunnus ja salasana.'
        );
        document.querySelector('.container').insertBefore(errorEl, el);
      });
  });
}

async function setBackground() {
  document
    .querySelector('body')
    .classList.add(`bg-${Math.floor(Math.random() * 7)}`);
}

window.onload = async () => {
  const token = await getToken();
  setBackground();

  if (token) {
    loginWithToken(token);
  } else {
    render(Login, () => loginWithPassword());
  }
};
