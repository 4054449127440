import axios from 'axios';

let instance;

async function get(endpoint) {
  if (!instance) await create();

  return instance?.get(endpoint);
}

async function del(endpoint) {
  if (!instance) await create();

  return instance?.delete(endpoint);
}

async function post(endpoint, data) {
  if (!instance) await create();

  return instance?.post(endpoint, data);
}

async function put(endpoint, data) {
  if (!instance) await create();

  return instance?.put(endpoint, data);
}

async function create() {
  instance = axios.create({
    baseURL: process.env.API_URL,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + String(sessionStorage.getItem('mokki-token')),
    },
  });
}

export { create, get, post, del, put };
