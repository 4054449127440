import * as api from './vendor/api';
import { monthsIn } from './helper';
import * as calendar from './calendar';
import { createElement } from './utils';

let selected = [];
let allTimes = false;
let invalidDates = [];
const allDates = [];

const getProperDate = (date) => {
  const d = new Date(date);

  /* const values = date.split(/[^0-9]/),
    year = parseInt(values[0], 10),
    month = parseInt(values[1], 10) - 1, // Month is zero based, so subtract 1
    day = parseInt(values[2], 10),
    hours = values[3] ? parseInt(values[3], 10) : 6,
    minutes = values[4] ? parseInt(values[4], 10) : 0,
    seconds = values[5] ? parseInt(values[5], 10) : 0; */

  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 6, 0, 0);
};

const setSelected = () => {
  selected.forEach((date) => {
    document
      .querySelectorAll(
        `.day[data-date="${date}"]:not(.disabled):not(.reserved)`
      )
      .forEach((el) => el.classList.add('active'));
  });
};

const setSelectedArray = (firstDay, thisDay) => {
  selected = [firstDay];
  const date = getProperDate(`${firstDay} 06:00:00`);
  let j = 0;

  invalidDates = [];

  while (j < 1) {
    date.setDate(date.getDate() + 1);
    const newDate = date.toISOString().substr(0, 10);

    if (
      document
        .querySelector(`.day[data-date="${newDate}"]`)
        .classList.contains('disabled')
    ) {
      break;
    }
    if (selected.indexOf(newDate) < 0 && allDates.indexOf(newDate) < 0) {
      selected.push(newDate);
    }

    if (newDate >= thisDay || allDates.indexOf(newDate) > -1) {
      j += 1;
    }
  }

  document
    .querySelectorAll('.day.active')
    .forEach((el) => el.classList.remove('active'));

  setSelected();
};

const getTimes = (callback) => {
  if (allTimes) callback(allTimes);
  else {
    api.get('/reservations').then((response) => {
      if (response.status === 200) {
        allTimes = response.data.reservations;

        callback(allTimes);
      }
    });
  }
};

const init = (year, month) => {
  try {
    document.querySelector('.disclaimer').innerHTML = String(
      window.sessionStorage.getItem('mokki-disclaimer')
    );
    const reservationsStorage = window.sessionStorage.getItem(
      'mokki-reservations'
    )
      ? JSON.parse(window.sessionStorage.getItem('mokki-reservations'))
      : [];

    if (reservationsStorage.length > 0) {
      allTimes = reservationsStorage;
      window.sessionStorage.removeItem('mokki-reservations');
    }
  } catch (e) {
    console.log(e);
  }
  calendar.init(year, month, () => {
    getTimes((times) => {
      times.forEach((obj) => {
        const dateStart = getProperDate(obj.startTime);
        const start = getProperDate(obj.startTime);
        const reserved = getProperDate(obj.createdAt);

        let dateEnd = getProperDate(obj.endTime);
        let j = 0;

        while (j < 1) {
          const date = new Date(dateStart).toISOString().substr(0, 10);
          dateEnd = new Date(dateEnd).toISOString().substr(0, 10);

          allDates.push(date);

          const dateEl = document.querySelector(
            `.week .day[data-date="${date}"]`
          );
          if (dateEl) {
            dateEl.classList.add('reserved');
            dateEl.setAttribute(
              'title',
              `${obj.user} on varannut mökin ${reserved.getDate()}. ${
                monthsIn[reserved.getMonth()]
              } ${reserved.getFullYear()}`
            );
            dateEl.setAttribute('data-user', obj.user);
            dateEl.setAttribute(
              'data-start',
              start.toISOString().substr(0, 10)
            );
          }
          dateStart.setDate(dateStart.getDate() + 1);
          if (String(date) === String(dateEnd)) {
            j += 1;
          }
        }
      });

      setSelected();
    });

    document.querySelectorAll('.week .day').forEach((el) =>
      el.addEventListener('click', (event) => {
        const self = event.target;
        if (
          self.classList.contains('reserved') &&
          !self.classList.contains('prevented') &&
          selected.length === 0
        ) {
          const info = createElement(
            'div',
            'info',
            `<p>${self.getAttribute('title')}</p>`
          );
          if (
            self.getAttribute('data-user') ===
              window.sessionStorage.getItem('mokki-user') &&
            self.getAttribute('data-start') >=
              new Date().toISOString().substr(0, 10)
          ) {
            info.innerHTML = `${info.innerHTML}<a href="#" class="btn btn-danger">Peruuta varaus</a>`;
            info.querySelector('.btn').addEventListener('click', (e) => {
              e.preventDefault();
              api
                .del(`/reservations/${self.getAttribute('data-start')}`)
                .then((response) => {
                  if (response.status === 200) {
                    window.sessionStorage.setItem(
                      'mokki-reservations',
                      JSON.stringify(response.data.reservations)
                    );

                    allTimes = false;

                    init(
                      parseInt(
                        self.getAttribute('data-start').split('-')[0],
                        10
                      ),
                      parseInt(
                        self.getAttribute('data-start').split('-')[1],
                        10
                      ) - 1
                    );
                  }
                });
            });
          }
          document.querySelector('.calendar-holder').append(info);
          info.addEventListener('click', () => {
            info.remove();
          });
        } else if (
          !self.classList.contains('disabled') &&
          !self.classList.contains('prevented')
        ) {
          if (document.querySelectorAll('.guide.confirm').length === 0) {
            if (document.querySelectorAll('.day.active').length > 0) {
              document.querySelectorAll('.guide').forEach((guide) => {
                guide.remove();
              });
              let firstDay = selected[0];
              let lastDay = self.getAttribute('data-date');

              setSelectedArray(firstDay, lastDay);

              firstDay = getProperDate(selected[0]);
              lastDay = getProperDate(selected[selected.length - 1]);

              const confirmElement = createElement('div', 'guide confirm');
              let confirm = `Vahvistetaanko päivät<br />${firstDay.getDate()}. ${
                monthsIn[firstDay.getMonth()]
              } ${firstDay.getFullYear()} - ${lastDay.getDate()}. ${
                monthsIn[lastDay.getMonth()]
              } ${lastDay.getFullYear()}?`;

              if (invalidDates.length > 0) {
                confirm += `<br><br>Varaukseen ei lasketa päiviä ${invalidDates
                  .map((date) => {
                    const d = date.split('-').map((day) => parseInt(day, 10));
                    return `${d[2]}.${d[1]}.`;
                  })
                  .join(', ')}`;
              }
              const price = (selected.length - 1) * 10;
              confirm += `<br /><br /><a href="#" class="btn btn-success">Kyllä</a>&nbsp;&nbsp;&nbsp;<a href="#" class="btn btn-danger">Ei</a><br><br>
                Kesän 2020 palaverissa on sovittu että mökin varausmaksu on jatkossa 10 euroa/yö varaaja.<br>
                Valituilla päivillä maksettavaksi tulee ${price} euroa.<br><br>
                Tili: Nuuviahon mökkitili FI80 53600450216638.</div>`;

              confirmElement.innerHTML = confirm;

              document.querySelector('body').append(confirmElement);
              document.querySelector('.guide').classList.add('open');

              document
                .querySelector('.guide .btn-danger')
                .addEventListener('click', (e) => {
                  e.preventDefault();
                  document
                    .querySelectorAll('.day.active')
                    .forEach((dayEl) => dayEl.classList.remove('active'));
                  document.querySelector('.guide').remove();
                  selected = [];
                });

              document
                .querySelector('.guide .btn-success')
                .addEventListener('click', (e) => {
                  e.preventDefault();

                  document.querySelector('.guide').innerHTML = `
              <div class="loader-holder">
                <div class="loader-content">
                  <span class="loader"></span>
                </div>
              </div>`;

                  api
                    .post(
                      '/reservations',
                      {
                        start: selected[0],
                        end: selected[selected.length - 1],
                      },
                      {
                        headers: {
                          authorization: `Bearer ${window.sessionStorage.getItem(
                            'mokki-token'
                          )}`,
                        },
                      }
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        window.sessionStorage.setItem(
                          'mokki-reservations',
                          JSON.stringify(response.data.reservations)
                        );
                        allTimes = false;
                        init(
                          parseInt(selected[0].split('-')[0], 10),
                          parseInt(selected[0].split('-')[1], 10) - 1
                        );
                        selected = [];

                        document.querySelector('.guide').remove();
                      }
                    });
                });
            } else {
              self.classList.add('active');
              selected = [self.getAttribute('data-date')];
              document
                .querySelectorAll('body .guide')
                .forEach((guide) => guide.remove());
              const guideElement = createElement(
                'div',
                'guide',
                'Valitse lähtöpäivä'
              );
              document.querySelector('body').append(guideElement);
              document.querySelector('.guide').classList.add('open');
            }
          }
        }
      })
    );

    document
      .querySelector('.week .day:not(.reserved):not(.disabled):not(.prevented)')
      .addEventListener('hover', (e) => {
        if (
          selected.length > 0 &&
          document.querySelectorAll('.guide.confirm').length === 0
        ) {
          const firstDay = selected[0];
          const thisDay = e.target.getAttribute('data-date');

          if (thisDay !== firstDay) setSelectedArray(firstDay, thisDay);
        }
      });
  });
};

export default { init };
