export function createElement(type, cls, html) {
  const el = document.createElement(type);
  if (cls) {
    cls.split(' ').forEach((c) => el.classList.add(c));
  }
  if (html) {
    el.innerHTML = html;
  }
  return el;
}

export function init() {}
